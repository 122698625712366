<template>
  <div class="home">
    <b-container fluid="xl">
      <h2 class="om-color"><b>Welcome to {{ brandName }}</b></h2>
      <p class="title-description">What do you want to do today?</p>
      <b-row>
        <div class="col-xl-3 col-sm-6" v-if="prospectEnabled">
          <a href="/prospect">
            <div class="trigger-card text-center">
              <b-icon icon="envelope-open" class="trigger-card-img"></b-icon>
              <h3>Prospect</h3>
              <p>
                Automate all your prospecting and stay a step ahead of the
                competition.
              </p>
              <b-button class="btn-om-solid btn-lg w-90">Get Started</b-button>
            </div>
          </a>
        </div>
        <div class="col-xl-3 col-sm-6" v-if="spotlightEnabled">
          <a href="/spotlight">
            <div class="trigger-card text-center">
              <b-icon icon="file-richtext" class="trigger-card-img"></b-icon>
              <h3>Spotlight</h3>
              <p>
                Produce high quality client facing material to increase your
                conversions.
              </p>
              <b-button class="btn-om-solid btn-lg w-90">Get Started</b-button>
            </div>
          </a>
        </div>
        <div class="col-xl-3 col-sm-6" v-if="insightEnabled">
          <a href="/insight">
            <div class="trigger-card text-center">
              <b-icon icon="bar-chart-fill" class="trigger-card-img"></b-icon>
              <h3>Insight</h3>
              <p>
                Track your performance vs. your competitors to stay ahead of the
                curve.
              </p>
              <b-button class="btn-om-solid btn-lg w-90">Get Started</b-button>
            </div>
          </a>
        </div>
        <div class="col-xl-3 col-sm-6" v-if="searchEnabled">
          <a href="/search">
            <div class="trigger-card text-center disabled-card">
              <b-icon icon="house" class="trigger-card-img"></b-icon>
              <h3>Search</h3>
              <p>
                View our comprehensive property data to know all there is to
                know about a property.
              </p>
              <b-button class="btn-om-solid btn-lg w-90">Get Started</b-button>
            </div>
          </a>
        </div>
        <div class="col-xl-3 col-sm-6" v-if="matchEnabled">
          <a href="/match">
            <div class="trigger-card text-center disabled-card">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-magic trigger-card-img" viewBox="0 0 16 16">
                <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707zM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1zM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707zM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0z"/>
              </svg>
              <h3>Match</h3>
              <p>
                Match your contacts list with our property triggers
              </p>
              <b-button class="btn-om-solid btn-lg w-90">Get Started</b-button>
            </div>
          </a>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      brandName: process.env.VUE_APP_BRAND_NAME,
    };
  },
  computed: {
    ...mapState({
      prospectEnabled: (state) => state.auth.user.prospect_enabled,
      spotlightEnabled: (state) => state.auth.user.spotlight_enabled,
      insightEnabled: (state) => state.auth.user.insight_enabled,
      searchEnabled: (state) => state.auth.user.search_enabled,
      matchEnabled: (state) => state.auth.user.match_enabled,
    }),
  },
  created() {
    this.$store.dispatch("auth/user");
  },
};
</script>

<style lang="scss" scoped>
.w-90 {
  width: 90%;
}

.st0 {
  fill: none;
}

.st1 {
  fill: currentcolor;
}

a {
  text-decoration: none;
}

.title-description {
  margin-bottom: 2rem !important;
}

.disabled-card {
  color: #9ea3ad;
}

.disabled-card:hover {
  background-color: #f8f9fb;
  border-color: #dededf;
  cursor: initial;
}

.disabled-card > h4 {
  color: #525b6c;
}

.trigger-card {
  border: 1px #dededf solid;
  background: #f8f9fb;
  border-radius: 3px;
  min-height: 40.3rem;
  margin-top: 1rem;
  color: var(--eastbay-color);
  padding: 6rem 1.3rem 1.7rem 1.3rem;

  &:hover {
    color: #fff;
    background: var(--eastbay-color);
    border-color: var(--om-color);
  }

  h3 {
    font-weight: bold;
    font-size: 2.45rem;
    margin-bottom: 1rem;
  }

  p {
    height: 3.9rem;
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.3rem;
    margin: 0 1.1rem 2rem 1.2rem;
  }

  &-img {
    margin: 0 0 4rem 0;
    width: 65%;
    height: 14.25rem;
  }
}
</style>
